import { ConfigProvider, Input as AntInput, InputProps as AntInputProps, InputRef } from 'antd';
import cn from 'classnames';
import { CSSProperties, useEffect, useState } from 'react';
import React from 'react';
import InputMask from 'react-input-mask';
import { transliterate } from 'utils/transliterate';

import { replaceNumber } from '../../utils/replaceNumber';
import styles from './index.module.scss';

type InputProps = Omit<
	AntInputProps,
	'addonAfter' | 'addonBefore' | 'allowClear' | 'bordered' | 'showCount' | 'prefix' | 'size' | 'type'
> & {
	wrapperStyle?: CSSProperties;
	isPhone?: boolean;
	isTransliterate?: boolean;
	isNumber?: boolean;
	errorMessage?: string | null;
};

const Input = React.forwardRef<InputRef, InputProps>(
	(
		{
			wrapperStyle,
			id,
			placeholder,
			className,
			isNumber = false,
			errorMessage,
			required,
			isPhone,
			isTransliterate,
			onChange,
			value,
			...props
		},
		ref
	) => {
		const [inputClassName, setInputClassName] = useState<string>('');
		const [valueInput, setValueInput] = useState<string>(String(value || ''));

		const handleInputChange = (evt: React.ChangeEvent<HTMLInputElement>): void => {
			let value = evt.target.value;

			if (isNumber) {
				value = replaceNumber(evt.target.value);
			}

			if (isTransliterate) {
				value = transliterate(evt.target.value.toUpperCase());
			}

			setValueInput(value);
			onChange && onChange({ ...evt, target: { ...evt.target, value } });
		};

		useEffect(() => {
			if (isPhone) {
				setInputClassName(document.querySelector('input.phone-input + input')?.className || '');
			}
		}, [isPhone]);

		return (
			<ConfigProvider theme={{ token: { controlHeight: 38, borderRadius: 4 } }}>
				<div className={cn(className, styles.wrap)} style={wrapperStyle}>
					{isPhone ? (
						<>
							<InputMask
								{...props}
								value={String(value)}
								className={cn(styles.input, inputClassName, styles.input__phone, 'phone-input')}
								id={id}
								placeholder=" "
								mask="+375 99 999 99 99"
								maskChar=""
								required={required}
								onChange={handleInputChange}
							/>
							<AntInput ref={ref} />
						</>
					) : (
						<AntInput
							{...props}
							className={styles.input}
							id={id}
							placeholder=" "
							bordered
							required={required}
							value={valueInput || value}
							ref={ref}
							onChange={handleInputChange}
							status={errorMessage ? 'error' : ''}
						/>
					)}
					{placeholder && (
						<label className={styles.label} htmlFor={id}>
							{placeholder} {required && <span className={styles.asterisk}>*</span>}
						</label>
					)}
					{errorMessage && <span className={styles.error}>{errorMessage}</span>}
				</div>
			</ConfigProvider>
		);
	}
);

export default Input;
