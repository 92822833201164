import Button from '@components/buttons/button';
import IconButton from '@components/buttons/icon_btn';
import ContextMenu from '@components/context-menu';
import Modal from '@components/modal';
import NoData from '@components/no-data';
import PostalInfoModal from '@components/postal-info-modal';
// import colors from '@config/colors';
import { useStore } from '@hooks/useStore';
import Icon from '@images/index';
import ModalOrderCollectBags from '@pages/main/tasks/components/emall/components/modal-order-collect-bags';
import { IStateConfirmModalCollect } from '@pages/main/visits/components/visit';
import OrderService from '@services/order';
import { IEmallTask } from '@store/tasks';
import { Tag } from 'antd';
import Table, { ColumnsType } from 'antd/es/table';
import { observer } from 'mobx-react-lite';
import { FC, useState } from 'react';
// import { plural } from 'utils/plural';
import { unpropagatedClickHandler } from 'utils/unpropagatedClickHandler';

import ModalConfirmTypeCollect from '../modal-confirm-type-collect';
import ModalOrders from '../modal-orders';
import styles from './index.module.scss';

const EmallTasksTable: FC = () => {
	const { operator, notice, print, tasks, loadTask, setLoading } = useStore();
	const [cancellingOrder, setCancelllingOrder] = useState<IEmallTask | null>(null);
	const [selectedItem, setSelectedItem] = useState<IEmallTask | null>(null);
	const [stateConfirmModal, setStateConfirmModal] = useState<IStateConfirmModalCollect>({
		id: null,
		isOpen: false,
	});

	// const extendOrder = (id: number): void => {
	// 	setLoading(true);
	// 	OrderService.extendOrder(id)
	// 		.then(({ data }) => notice.setNotice({ type: 'success', message: data.message, hasButton: false }))
	// 		.catch((err) => notice.setNotice({ message: err?.error || err?.message || '' }))
	// 		.finally(() => {
	// 			setLoading(false);
	// 			loadTask();
	// 		});
	// };

	const columns: ColumnsType<IEmallTask> = [
		{
			dataIndex: 'info',
			render: (_, { market_id }) => (
				<div style={{ display: 'flex', gap: 8 }}>
					<div style={{ paddingTop: 5 }}>
						<Icon imageName="ShoppingBag" />
					</div>
					<div>
						<div style={{ fontWeight: 500 }}>{market_id}</div>
						{/* <div style={{ color: expired_days < 8 ? colors.black : colors.red }}> */}
						<div>
							Истек срок хранения
							{/* Истек срок {expired_days > 0 && `${expired_days} ${plural(expired_days, 'день', 'дня', 'дней')}`} */}
						</div>
					</div>
				</div>
			),
			width: 195,
		},
		{
			dataIndex: 'name',
			render: (_, { receiver_fio }) => <div>{receiver_fio}</div>,
			width: 328,
		},
		{
			dataIndex: 'phone',
			render: (_, { receiver_phone_number }) => <div style={{ width: 136 }}>{receiver_phone_number}</div>,
			width: 162,
		},
		{
			dataIndex: 'positions',
			render: (_, { bags }) => (
				<div>
					{bags?.map((value) => (
						<Tag key={value.id}>{value.name}</Tag>
					))}
				</div>
			),
		},
		{
			render: (_, task) => (
				<div style={{ display: 'flex', justifyContent: 'flex-end', gap: 12 }}>
					{/* <Button type="primary" onClick={unpropagatedClickHandler(() => extendOrder(task.id))}>
						Продлить на {task.can_extended_for} {plural(task.can_extended_for, 'день', 'дня', 'дней')}
					</Button> */}

					{(operator?.isRouting && !operator.isEmallPackingMode && !task.has_packages) ||
					(operator?.isRouting && task.need_collect) ? (
						<Button
							type="primary"
							onClick={unpropagatedClickHandler(() => setStateConfirmModal({ isOpen: true, id: task.id }))}
						>
							Собрать заказ
						</Button>
					) : (
						<Button onClick={unpropagatedClickHandler(() => setCancelllingOrder(task))}>Отменить</Button>
					)}
				</div>
			),
			width: 100,
		},
		{
			render: (_, { market_id, id }) => (
				<div className={styles.actions}>
					<ContextMenu
						items={[
							{
								text: 'Товарный чек',
								onClick: unpropagatedClickHandler(() =>
									print.setPrintPreview({ type: 'sales-receipt', number: String(market_id), id })
								),
							},
							{
								text: 'Список товаров',
								onClick: unpropagatedClickHandler(() =>
									print.setPrintPreview({
										type: 'order-for-collect',
										number: String(market_id),
										ids: [Number(id)],
									})
								),
							},
						]}
					>
						<IconButton imageName="Sheet" style={{ margin: '0 4px' }} />
					</ContextMenu>
				</div>
			),
			width: 44,
		},
	];

	if (!tasks?.filteredOrders) return <NoData text="Заданий нет" />;

	return (
		<>
			<Table
				rowKey="id"
				showHeader={false}
				pagination={false}
				columns={columns}
				dataSource={tasks.filteredOrders}
				onRow={(obj) => ({
					onClick: () => {
						setSelectedItem(obj);
					},
					className: styles.row,
				})}
				className={styles.table}
				locale={{ emptyText: 'Нет данных' }}
			/>
			<Modal
				className={styles.confirm}
				title={`Вы уверены, что хотите отменить заказ №${cancellingOrder?.market_id} ?`}
				okText={'Отменить заказ'}
				cancelText="Отмена"
				open={!!cancellingOrder}
				onCancel={() => setCancelllingOrder(null)}
				onOk={() => {
					setLoading(true);
					OrderService.cancelOrder({ orderId: cancellingOrder?.id as number }, { cancel_reason_id: 27 })
						.then(() => setCancelllingOrder(null))
						.catch((err) => notice.setNotice({ message: err?.error || err?.message || '' }))
						.finally(() => {
							setLoading(false);
							loadTask();
						});
				}}
				hasLines={false}
				centered
				width={416}
			/>
			<ModalConfirmTypeCollect stateConfirmModal={stateConfirmModal} setStateConfirmModal={setStateConfirmModal} />
			<ModalOrders />
			<ModalOrderCollectBags />
			<PostalInfoModal id={selectedItem?.id || null} type="order" onClose={() => setSelectedItem(null)} />
		</>
	);
};

export default observer(EmallTasksTable);
